import { useRef, useState, useEffect } from 'react';
import OrdersUI from './OrdersUI';
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer } from "../../Utils/dateUtils";
import { isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";

const Orders = () => {
    const [modalShow, setModalShow] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedTranporterOptions, setSelectedTranporterOptions] = useState([]);
    const [selectedAccountantOptions, setSelectedAccountantOptions] = useState([]);
    const [selectedSupplierOptions, setSelectedSupplierOptions] = useState([]);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [selectedImages, setSelectedImages] = useState({ images: [], index: null });

    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();

    const initialOrderData = {
        id: null,
        date: "",
        challanNo: "",
        material: "",
        vehicalNo: "",
        grossWt: "",
        percentage: "",
        netWt: "",
        transporter: "",
        supplierLoadingSource: "",
        royality: "",
        companyTicketNo: "",
        images: [],
        remarks: "",
        action: "Add",
    };
    const [orderData, setOrderData] = useState(initialOrderData);

    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getOrderList();
        }
    }, [activePage, dates, searchQuery, selectedTranporterOptions, selectedAccountantOptions, selectedSupplierOptions]);

    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };

    const getOrderList = () => {
        setLoading(true);
        const query = `?page=${activePage}&per_page=10`;
        const data = {
            transporters: selectedTranporterOptions,
            accountants: selectedAccountantOptions,
            suppliers: selectedSupplierOptions,
            query: searchQuery,
            startDate: changeDateFormatForServer(dates.startDate),
            endDate: changeDateFormatForServer(dates.endDate),
        };

        apiHelper.getOrders(query, data).then((response) => {
            if (response?.isSuccess) {
                setOrderList(response.data);
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    }

    const validationExport = () => {
        let validate = true;
        if (isNullOrEmpty(dates.startDate)) {
            toast.warn(stringConstants.START_DATE_REQUIRED);
            validate = false;
        }
        else if (isNullOrEmpty(dates.endDate)) {
            toast.warn(stringConstants.END_DATE_REQUIRED);
            validate = false;
        }
        return validate;
    }

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'images') {
            setOrderData(prevState => ({
                ...prevState,
                images: Array.from(files),
            }));
        } else {
            setOrderData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const validateData = () => {
        const { date, challanNo, material, vehicalNo, grossWt, percentage, netWt, transporter, supplierLoadingSource, royality, companyTicketNo, remarks, } = orderData;

        let validData = true;

        if (isNullOrEmpty(date)) {
            toast.warn(stringConstants.ORDER_DATE_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(challanNo)) {
            toast.warn(stringConstants.ORDER_CHALLAN_NO_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(material)) {
            toast.warn(stringConstants.ORDER_MATERIAL_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(vehicalNo)) {
            toast.warn(stringConstants.ORDER_VEHICAL_NO_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(grossWt)) {
            toast.warn(stringConstants.ORDER_GROSS_WT_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(percentage)) {
            toast.warn(stringConstants.ORDER_PERCENTAGE_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(netWt)) {
            toast.warn(stringConstants.ORDER_NET_WT_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(transporter)) {
            toast.warn(stringConstants.ORDER_TRANSPORTER_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(supplierLoadingSource)) {
            toast.warn(stringConstants.ORDER_SUPPLIER_LOADING_SOURCE_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(royality)) {
            toast.warn(stringConstants.ORDER_ROYALITY_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(companyTicketNo)) {
            toast.warn(stringConstants.ORDER_COMPANY_TICKET_NO_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(remarks)) {
            toast.warn(stringConstants.ORDER_REMARKS_REQUIRED);
            validData = false;
        }
        return validData;
    };

    const upsertOrder = () => {
        if (validateData()) {
            setButtonLoading(true);
            const formData = new FormData();
            // formData.append('id', orderData.id);
            formData.append('date', orderData.date);
            formData.append('challan_no', orderData.challanNo);
            formData.append('material_id', orderData.material);
            formData.append('vehicle_no', orderData.vehicalNo);
            formData.append('gross_wt', orderData.grossWt);
            formData.append('deduction', orderData.percentage);
            formData.append('net_wt', orderData.netWt);
            formData.append('transporter_id', orderData.transporter);
            formData.append('supplier_id', orderData.supplierLoadingSource);
            formData.append('royality', orderData.royality);
            formData.append('company_ticket_no', orderData.companyTicketNo);
            formData.append('remark', orderData.remarks);
            orderData.images.forEach((file, index) => {
                formData.append(`attachments[${index}]`, file); // Include index in key
            });
            apiHelper.upsertOrder(formData).then(response => {
                setButtonLoading(false);
                if (response?.isSuccess) {
                    toast.success(response?.message);
                    handleClose();
                    getOrderList();
                } else {
                    toast.warn(response?.message);
                }
            });
        }
    };

    const downloadExcelFile = () => {
        if (validationExport()) {
            setButtonLoading(true);
            const query = `?page=${activePage}&per_page=10`;
            const data = {
                transporters: selectedTranporterOptions,
                accountants: selectedAccountantOptions,
                suppliers: selectedSupplierOptions,
                query: searchQuery,
                startDate: changeDateFormatForServer(dates.startDate),
                endDate: changeDateFormatForServer(dates.endDate),
            };

            apiHelper.orderExport(query, data).then((response) => {
                if (response?.isSuccess) {
                    if (!isNullOrEmpty(response.data.file_url)) {
                        const link = document.createElement('a');
                        link.href = response.data.file_url;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    toast.warn(response?.message);
                }
                setButtonLoading(false);
            });
        }
    }

    const handleImageClick = (images, index) => {
        setSelectedImages({ images, index });
        setShowImagePreview(true);
    };

    const handleClose = () => {
        setModalShow(false);
        setOrderData(initialOrderData);
    }

    return (
        <OrdersUI
            loading={loading}
            buttonLoading={buttonLoading}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleClose={handleClose}
            orderList={orderList}
            activePage={activePage}
            onPageChange={onPageChange}
            dates={dates}
            setDates={setDates}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderData={orderData}
            setOrderData={setOrderData}
            upsertOrder={upsertOrder}
            downloadExcelFile={downloadExcelFile}
            validationExport={validationExport}
            selectedTranporterOptions={selectedTranporterOptions}
            setSelectedTranporterOptions={setSelectedTranporterOptions}
            selectedAccountantOptions={selectedAccountantOptions}
            setSelectedAccountantOptions={setSelectedAccountantOptions}
            selectedSupplierOptions={selectedSupplierOptions}
            setSelectedSupplierOptions={setSelectedSupplierOptions}
            handleChange={handleChange}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            showImagePreview={showImagePreview}
            setShowImagePreview={setShowImagePreview}
            setSelectedImages={setSelectedImages}
        />
    )
}

export default Orders;
