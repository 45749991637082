import { useEffect, useState } from 'react'
import ProfileUI from './ProfileUI'
import { isLengthInBetween, isNullOrEmpty } from '../../Utils/stringUtils';
import * as toast from "../../Wrapper/toast/toast";
import * as apiHelper from "../../Services/ApiHelper";
import * as stringConstants from "../../Constants/stringConstants";

const Profile = () => {
    const initialdata = {
        name: "",
        mno: null
    }
    const [profile, setProfile] = useState(initialdata);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUpdateProfile = (e) => {
        e.preventDefault();

        const { name, mno } = profile;
        if (isNullOrEmpty(name)) {
            toast.warn(stringConstants.NAME_REQUIRED)
            return;
        } else if (isNullOrEmpty(mno)) {
            toast.warn(stringConstants.PHONE_REQUIRED)
            return;
        } else if (!isLengthInBetween(mno, 9, 14)) {
            toast.warn(stringConstants.INCORRECT_PHONE)
            return;
        }

        setButtonLoading(true);
        apiHelper.updateProfile(profile).then(response => {
            if (response?.isSuccess) {
                toast.success(response?.message);
            } else {
                toast.warn(response?.message);
            }
            setButtonLoading(false);
        });
    };

    useEffect(() => {
        if (isNullOrEmpty(profile.name)) {
            getProfile()
        }
    }, [profile])

    const getProfile = () => {
        setLoading(true)
        apiHelper.getProfile().then(response => {
            if (response?.isSuccess) {
                setProfile({ ...profile, name: response.data.name, mno: response.data.mno });
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    };

    return (
        <>
            <ProfileUI
                profile={profile}
                setProfile={setProfile}
                handleUpdateProfile={handleUpdateProfile}
                buttonLoading={buttonLoading}
                loading={loading}
            />
        </>
    )
}

export default Profile