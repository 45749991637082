import React, { useState } from "react";
import ChangePasswordUI from "./ChangePasswordUI";
import { isNullOrEmpty } from "../../Utils/stringUtils";
import * as stringConstants from "../../Constants/stringConstants";
import * as toast from "../../Wrapper/toast/toast";
import { changePassword } from "../../Services/ApiHelper";

const ChangePassword = () => {

  const [password, setPassword] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  const [buttonLoading, setButtonLoading] = useState(false)

  const changeProfilePassword = async (e) => {
    e.preventDefault();
    setButtonLoading(true)
    if (!password.oldPassword || isNullOrEmpty(password.oldPassword)) {
      toast.warn(stringConstants.OLD_PASSWORD_REQUIRED);
    }
    else if (password.oldPassword.length < 6 || password.oldPassword.length > 12) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    }
    else if (!password.newPassword || isNullOrEmpty(password.newPassword)) {
      toast.warn(stringConstants.NEW_PASSWORD_REQUIRED);
    }
    else if (password.newPassword.length < 6 || password.newPassword.length > 12) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    }
    else if (!password.confirmPassword || isNullOrEmpty(password.confirmPassword)) {
      toast.warn(stringConstants.CONFIRM_PASSWORD_REQUIRED);
    }
    else if (password.newPassword !== password.confirmPassword) {
      toast.warn(stringConstants.PASSWORD_CONFIRM_PASSWORD_MATCH);
    }
    else {
      const response = await changePassword({ old_password: password.oldPassword, new_password: password.newPassword, confirm_password: password.confirmPassword });
      if (response?.isSuccess) {
        toast.success(response.message);
        setButtonLoading(false)
      } else {
        toast.error(response.message);
        setButtonLoading(false)
      }
    }
    setButtonLoading(false)
  }

  return (
    <>
      <ChangePasswordUI
        password={password}
        setPassword={setPassword}
        changeProfilePassword={changeProfilePassword}
        buttonLoading={buttonLoading}
      />
    </>
  );
};

export default ChangePassword;
