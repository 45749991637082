import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from './common';
import * as urlconstant from '../Constants/urlConstants'

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = urlconstant.BASE_URL;

export const getHeaders = (model) => {
  let headers = {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.AUTH) !== null ? `Bearer ${commonServices.getLocalData(storageConstants.AUTH)}` : null,
    'x-api-key': process.env.REACT_APP_CLIENT_ID
  };

  if (!(model instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
};

/** @param {string} resource */
export const getAll = (resource, model = '') => {
  var headers = getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${model}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const get = (resource, id) => {
  var headers = getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${id}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const post = (resource, model) => {
  const headers = getHeaders(model);
  return axios
    .post(`${BASE_URL}${resource}`, model, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const put = (resource, model) => {
  var headers = getHeaders();
  return axios
    .put(`${BASE_URL}${resource}`, model, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteAPI = (id) => {
  var headers = getHeaders();
  return axios
    .delete(`${BASE_URL}${id}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};
