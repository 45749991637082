import { Image } from "react-bootstrap";
import BasicModal from "../_widgets/Modal/BasicModal";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./carousel.scss";
import Button from "../_widgets/Button/Button";

const ModalImageCarousel = ({ modalShow, setModalShow, selectedImages, setSelectedImages, }) => {

    const downloadImage = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <BasicModal
            show={modalShow}
            size="lg"
            setModalShow={setModalShow}
            heading='Image Preview'
            handleClose={() => setModalShow(false)}
        >
            <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} selectedItem={selectedImages.index} onChange={(index) => setSelectedImages({ ...selectedImages, index })}>
                {selectedImages.images.map((url, i) => (
                    <div key={i} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                        <Button
                            style={{
                                marginBottom: "10px",
                            }}
                            buttonType="primary"
                            text="Download"
                            onClick={
                                () => downloadImage(process.env.REACT_APP_BASE_URL + "storage/" + url)
                            }
                        />
                        <Image style={{ width: "100%", objectFit: "cover" }} src={process.env.REACT_APP_BASE_URL + "storage/" + url} alt={`Attachment ${i + 1}`} />
                    </div>
                ))}
            </Carousel>
        </BasicModal>
    );
}

export default ModalImageCarousel;
