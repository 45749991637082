import Text from '../Text/Text';
import BasicModal from './BasicModal';

const ModalConfirmation = ({ show, handleClose, heading, buttonLoading, button1Click, button1Text, childrenText }) => {
    return (
        <>
            <BasicModal
                size='sm'
                show={show}
                handleClose={handleClose}
                heading={heading}
                loading={buttonLoading}
                footer={true}
                button1Click={button1Click}
                button1Text={button1Text}
            >
                <Text text={childrenText || 'Are you sure you want to perform this action?'} />
            </BasicModal>
        </>
    )
}

export default ModalConfirmation;
