import React, { useState } from "react";
import "./resetPassword.scss";
import ResetPasswordUI from "./ResetPasswordUI";
import * as apiHelper from "../../Services/ApiHelper";
import { useNavigate } from "react-router-dom";
import * as toast from "../../Wrapper/toast/toast";
import * as stringConstants from "../../Constants/stringConstants";
import { isNullOrEmpty } from "../../Utils/stringUtils";
import { isEmailValid } from "../../Services/common";

const ResetPassword = ({ location }) => {
  const [OtpPending, setOtpPending] = useState(true);
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const resetPassword = (e) => {
    e.preventDefault();

    if (!otp || isNullOrEmpty(otp)) {
      toast.warn(stringConstants.OTP_REQUIRED);
    }
    else if (otp.length < 4) {
      toast.warn(stringConstants.VALID_OTP);
    }
    else if (!newPassword || isNullOrEmpty(newPassword)) {
      toast.warn(stringConstants.NEW_PASSWORD_REQUIRED);
    }
    else if (newPassword.length < 6 || newPassword.length > 12) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    }
    else if (!confirmPassword || isNullOrEmpty(confirmPassword)) {
      toast.warn(stringConstants.CONFIRM_PASSWORD_REQUIRED);
    }
    else if (newPassword !== confirmPassword) {
      toast.warn(stringConstants.PASSWORD_CONFIRM_PASSWORD_MATCH);
    }
    else {
      apiHelper
        .resetPassword({ email, otp, new_password: newPassword, confirm_password: confirmPassword })
        .then((response) => {
          if (response?.isSuccess) {
            toast.success(response.message);
            navigate("/");
          } else {
            toast.error(response.message);
          }
        })
    }
  };

  const forgotPassword = (e) => {
    e.preventDefault();

    if (!email || isNullOrEmpty(email)) {
      toast.warn(stringConstants.EMAIL_REQUIRED);
    } else if (!isEmailValid(email)) {
      toast.warn(stringConstants.INCORRECT_EMAIL);
    } else {
      apiHelper.forgotPassword({ email }).then((response) => {
        if (response?.isSuccess) {
          toast.success(response.message);
          setOtpPending(false);
        } else {
          toast.error(response.message);
        }
      })
    }
  };

  return (
    <div>
      <ResetPasswordUI
        OtpPending={OtpPending}
        setOtpPending={setOtpPending}
        location={location}
        email={email}
        otp={otp}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        setEmail={setEmail}
        setOtp={setOtp}
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
        resetPassword={resetPassword}
        forgotPassword={forgotPassword}
      />
    </div>
  );
};

export default ResetPassword;
