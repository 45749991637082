import React from "react";
import { Form, Row, Col, InputGroup, Image } from "react-bootstrap";
import Button from "../_widgets/Button/Button";
import Text from "../_widgets/Text/Text";
import LostImage from "../../Assets/img/lost_password.png";
import IconResource from "../../Wrapper/IconResource/IconResource";
import Input from "../_widgets/Input/Input";

const ResetPasswordUI = ({ OtpPending, location, email, setEmail, otp, setOtp, newPassword, setNewPassword, confirmPassword, setConfirmPassword, resetPassword, forgotPassword, }) => {
  return (
    <div className="vh100 bg_primary">
      <Row className="h-100 mx-0 flex-column-reverse flex-md-row">
        <Col md={7} className="bg-white  rounded_right">
          <div className="d-flex align-items-center justify-content-between h-100 position-relative">
            {location.pathname !== "/" ? (
              <div className="back_btn">
                <Button buttonType="back" />
              </div>
            ) : (
              ""
            )}
            {OtpPending ? (
              <div className="login_form m-auto">
                <Text
                  className="login_header"
                  type={"h1"}
                  text="Lost your password?<br>Enter your details to recover."
                />
                <Text
                  className="login_sub_heading"
                  type={"small"}
                  text="Enter your details to proceed further"
                />
                <Form className="mt-6">
                  <Form.Label aria-label="Email Adress">
                    Email Address
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter your Email"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <IconResource type="envelop" />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="mt-4">
                    <Button
                      buttonType={"primary"}
                      onClick={(e) => forgotPassword(e)}
                      text="Recover"
                    />
                  </div>
                </Form>
              </div>
            ) : (
              <div className="login_form m-auto">
                <Text
                  className="login_header"
                  type={"h1"}
                  text="Enter your Password!"
                />
                <Text
                  className="login_sub_heading"
                  type={"small"}
                  text="Enter your details to proceed further"
                />
                <Form className="mt-6">
                  <Input
                    inputType="icon"
                    type="number"
                    label="OTP"
                    placeholder="Enter your OTP"
                    iconType="envelop"
                    value={otp}
                    max={4}
                    onChange={(e) => e.target.value.length < 5 && setOtp(e.target.value)}
                  />
                  <Input
                    inputType="password"
                    label="Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter New Password"
                  />
                  <Input
                    inputType="password"
                    label="Confirm Password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div className="mt-4">
                    <Button
                      buttonType={"primary"}
                      text="Recover"
                      onClick={(e) => resetPassword(e)}
                    />
                  </div>
                </Form>
              </div>
            )}
          </div>
        </Col>
        <Col md={5}>
          <div className="w-100 h-100 overflow-hidden side_image">
            <Image src={LostImage} className="img-fluid w-100 h-100" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordUI;
