import Text from "../_widgets/Text/Text";
import Input from "../_widgets/Input/Input";
import noData from "../../Assets/img/noData.png";
import Button from "../_widgets/Button/Button";
import NavBar from "../_widgets/NavBar/NavBar";
import { Table } from "react-bootstrap";
import BasicModal from "../_widgets/Modal/BasicModal";
import { Row, Col } from "react-bootstrap";
import CustomPagination from "../_widgets/Pagination/Pagination";
import DateRangePicker from "../_widgets/DateRangePicker/DateRangePicker";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import CustomTooltip from '../_widgets/Tooltip/CustomTooltip'
import IconResource from '../../Wrapper/IconResource/IconResource';
import { isNullOrEmpty } from "../../Utils/stringUtils";
import ModalConfirmation from "../_widgets/Modal/ModalConfirmation";
import MainLoader from "../_widgets/Loader/MainLoader";
import { warn } from "../../Wrapper/toast/toast";
import { maximumLengthAllowed } from "../../Constants/stringConstants";
import { changeDateFormatForDisplay } from "../../Utils/dateUtils";

const TransportersUI = ({ modalShow, setModalShow, handleClose, transporterList, onPageChange, activePage, showConfirmModal, setShowConfirmModal, handleDeleteConfirmation, buttonLoading, loading, dates, setDates, datePickerStartRef, datePickerEndRef, searchQuery, setSearchQuery, handleDeleteClick, transporterData, setTransporterData, upsertTransporter, handleEditClick, }) => {
    return (
        <>
            <AnimatedPage>
                <NavBar
                    button="Add Transporter"
                    buttonIcon={"users"}
                    heading={`All Transporters`}
                    onClick={() => document.body.classList.toggle("side_active")}
                    setModalShow={setModalShow}
                    Icon="menu"
                />

                <ModalConfirmation
                    show={showConfirmModal}
                    handleClose={() => setShowConfirmModal(false)}
                    heading='Delete Confirmation'
                    buttonLoading={buttonLoading}
                    button1Click={handleDeleteConfirmation}
                    button1Text={'Delete'}
                />

                <BasicModal
                    show={modalShow}
                    size="md"
                    setModalShow={setModalShow}
                    handleClose={handleClose}
                    heading={`${transporterData.action} Transporter`}
                    footer
                    button2Text={"Save"}
                    button2Click={upsertTransporter}
                    loading={buttonLoading}
                >
                    <div className="patients_ui">
                        <Row>
                            <Col xl={12}>
                                <div className="white_card text-start">
                                    <Row className="mb-3">
                                        <Col lg={12}>
                                            <Input
                                                placeholder="Enter Transporter Name"
                                                type="text"
                                                label="Transporter Name"
                                                value={transporterData.name}
                                                onChange={e => {
                                                    if (e.target.value.length <= 20) {
                                                        setTransporterData({ ...transporterData, name: e.target.value })
                                                    } else {
                                                        warn(maximumLengthAllowed(20))
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col lg={12}>
                                            <Input
                                                placeholder="Enter Notes/Remarks"
                                                type="text"
                                                as="textarea"
                                                rowspan={4}
                                                label="Notes/Remarks"
                                                value={transporterData.remark}
                                                onChange={e => {
                                                    if (e.target.value.length <= 50) {
                                                        setTransporterData({ ...transporterData, remark: e.target.value })
                                                    } else {
                                                        warn(maximumLengthAllowed(50))
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </BasicModal>

                <div className="patient_list white_card">
                    <div className="top_bar">
                        <Text type="h2" text="All Transporters" />
                        <Row className="search_filter">
                            <Col lg={5} xs={12} sm={4}>
                                <div>
                                    <Input
                                        placeholder="Search"
                                        id="searchInput"
                                        suffixIcon={`${searchQuery.length > 0 ? "cross" : "search"}`}
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onClickIconSuff={() => setSearchQuery("")}
                                    />
                                </div>
                            </Col>
                            <Col lg={7} sm={8}>
                                <div className="date_range_picker">
                                    <DateRangePicker
                                        startDate={dates?.startDate}
                                        setStartDate={(startDate) => setDates({ ...dates, startDate })}
                                        endDate={dates?.endDate}
                                        setEndDate={(endDate) => setDates({ ...dates, endDate })}
                                        onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                                        datePickerStartRef={datePickerStartRef}
                                        datePickerEndRef={datePickerEndRef}
                                        showDateShortcuts={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="custom_table position-relative">
                        {loading ? (
                            <MainLoader />
                        ) : transporterList?.data?.length > 0 ? (
                            <>
                                <div>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Transporter Name</th>
                                                <th>Notes/Remarks</th>
                                                <th>Updated At</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transporterList?.data?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.name}</td>
                                                        <td>{isNullOrEmpty(data.remark) ? '-' : data.remark}</td>
                                                        <td>{(changeDateFormatForDisplay(data.updated_at))}</td>
                                                        <td><div className="d-flex align-items-center gap-2 justify-content-center">
                                                            <CustomTooltip text="Edit" placement={"top"}>
                                                                <Button
                                                                    className="p-1 bg-transparent border-0"
                                                                    icon={
                                                                        <IconResource type="edit" color="var(--light-text)" />
                                                                    }
                                                                    onClick={() => handleEditClick(data)}
                                                                />
                                                            </CustomTooltip>
                                                            <CustomTooltip text="Delete" placement={"top"}>
                                                                <Button
                                                                    className="p-1 bg-transparent border-0"
                                                                    icon={<IconResource type="trash" color="red" />}
                                                                    onClick={() => handleDeleteClick(data?.id)}
                                                                />
                                                            </CustomTooltip>
                                                        </div></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <CustomPagination
                                    activePage={activePage}
                                    onPageChange={onPageChange}
                                    pageLength={transporterList?.per_page}
                                    totalData={transporterList?.total}
                                    lastPage={transporterList?.data.last_page}
                                />
                            </>
                        ) : (
                            <>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Transporter Name</th>
                                            <th>Notes/Remarks</th>
                                            <th>Updated At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </Table>
                                <div className="no_data position-relative">
                                    <img
                                        src={noData}
                                        className="img-fluid"
                                        style={{ width: "300px" }}
                                    />
                                    <Text type="h2" text="There is no data to display." />
                                    {/* <Button buttonType="primary" text="Add Medicine" /> */}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </AnimatedPage>

        </>
    )
}

export default TransportersUI