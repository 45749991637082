import { useRef, useState, useEffect } from 'react'
import SitesUI from './SitesUI';
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer } from "../../Utils/dateUtils";
import * as commonServices from "../../Services/common";
import { isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";

const Sites = () => {
    const [modalShow, setModalShow] = useState(false);
    const [sitesList, setSitesList] = useState([]);
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();

    const initialSiteData = {
        id: null,
        name: "",
        description: "",
        action: "Add",
        transporters: []
    }
    const [siteData, setSiteData] = useState(initialSiteData);

    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getSitesList();
        }
    }, [activePage, dates, searchQuery]);

    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };

    const getSitesList = () => {
        const query = `?page=${activePage}&per_page=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&query=${searchQuery}`;

        setLoading(true);
        apiHelper.getSites(query).then((response) => {
            if (response?.isSuccess) {
                setSitesList(response.data);
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    }

    const validateData = () => {
        const { name, description } = siteData;
        let validData = true;

        if (isNullOrEmpty(name)) {
            toast.warn(stringConstants.SITE_NAME_REQ);
            validData = false;
        } else if (isNullOrEmpty(description)) {
            toast.warn(stringConstants.SITE_DESC_REQUIRED);
            validData = false;
        }

        return validData;
    };

    const upsertSite = () => {
        if (validateData()) {
            setButtonLoading(true);
            const data = {
                id: siteData.id,
                name: siteData.name,
                description: siteData.description,
                transporters: siteData.transporters.map(option => option.value)
            }
            apiHelper.upsertSite(data).then(response => {
                setButtonLoading(false);
                if (response?.isSuccess) {
                    toast.success(response?.message);
                    handleClose();
                    commonServices.fetchSystemData();
                    getSitesList();
                } else {
                    toast.warn(response?.message);
                }
            });
        }
    }

    const clearData = () => {
        setSiteData(initialSiteData);
    }

    const handleClose = () => {
        setModalShow(false);
        clearData();
    }

    const handleEditClick = (data) => {
        setSiteData({ id: data.id, name: data.name, description: data.description, action: 'Edit', transporters: data.transporters });
        setModalShow(true);
    }

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowConfirmModal(true);
    }

    const handleDeleteConfirmation = () => {
        deleteSite(deleteId);
    }

    const deleteSite = (id) => {
        setButtonLoading(true);
        apiHelper.deleteSite(id).then((response) => {
            setButtonLoading(false);
            if (response?.isSuccess) {
                setShowConfirmModal(false);
                getSitesList();
                commonServices.fetchSystemData();
                toast.success(response.message);
            } else {
                toast.warn(response?.message);
            }
        });
    };

    return (
        <SitesUI
            loading={loading}
            buttonLoading={buttonLoading}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleClose={handleClose}
            sitesList={sitesList}
            activePage={activePage}
            onPageChange={onPageChange}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            handleDeleteConfirmation={handleDeleteConfirmation}
            dates={dates}
            setDates={setDates}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            siteData={siteData}
            setSiteData={setSiteData}
            upsertSite={upsertSite}
        />
    )
}

export default Sites;
