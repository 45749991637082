import React from "react";
import { motion } from "framer-motion";

const AnimatedPage = ({ children, variants }, props) => {
  const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
    transition: { duration: 1 },
  };
  return (
    <motion.div
      variants={variants ? variants : animations}
      initial="initial"
      animate="animate"
      exit="exit"
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
