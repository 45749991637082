import {
  FaLongArrowAltRight,
  FaRegEnvelopeOpen,
  FaInstagram,
  FaFacebookSquare,
  FaArrowAltCircleDown,
  FaListOl,
} from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { RiCheckDoubleFill } from "react-icons/ri";
import { IoMdMenu } from "react-icons/io";
import { PiCurrencyInrBold } from "react-icons/pi";
import { IoIosHelpCircleOutline, IoIosArrowDropdown } from "react-icons/io";
import { RxCross2, RxCrossCircled } from "react-icons/rx";
import { FcGoogle } from "react-icons/fc";
import { FiPlusCircle, FiUsers } from "react-icons/fi";
import { GiMedicines } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  IoLockClosedOutline,
  IoLockOpenOutline,
  IoBagAddOutline,
  IoArrowBackOutline,
  IoSearchOutline,
  IoFilterSharp,
} from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import { AiOutlineUserAdd, AiOutlineDashboard } from "react-icons/ai";
import { RiMedicineBottleFill } from "react-icons/ri";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineShortcut } from "react-icons/md";

import { MdEditNote } from "react-icons/md";
import { GoTrash } from "react-icons/go";
import { BiPurchaseTag } from "react-icons/bi";
import Loader from "react-spinners/ClipLoader";
import { MdOutlineManageAccounts } from "react-icons/md";
import { FaSitemap } from "react-icons/fa";


const IconResource = ({ type, size = 20, ...props }) => {
  switch (type) {
    case "loader":
      return <Loader color="var(--primary)" size={14} />;
    case "dashboard":
      return <AiOutlineDashboard size={size} {...props} />;
    case "search":
      return <IoSearchOutline size={size} {...props} />;
    case "bag":
      return <IoBagAddOutline size={size} {...props} />;
    case "users":
      return <FiUsers size={size} {...props} />;
    case "medicine":
      return <GiMedicines size={size} {...props} />;
    case "addMedicine":
      return <RiMedicineBottleFill size={size} {...props} />;
    case "report":
      return <HiOutlineDocumentReport size={size} {...props} />;
    case "help":
      return <IoIosHelpCircleOutline size={size} {...props} />;
    case "rupee":
      return <PiCurrencyInrBold size={size} {...props} />;
    case "doubletick":
      return <RiCheckDoubleFill size={size} {...props} />;
    case "plus":
      return <GoPlus size={size} {...props} />;
    case "cross":
      return <RxCross2 size={size} {...props} />;
    case "edit":
      return <MdEditNote size={25} {...props} />;
    case "trash":
      return <GoTrash size={size} {...props} />;
    case "delete":
      return <RxCrossCircled color="red" size={size} {...props} />;
    case "google":
      return <FcGoogle size={size} {...props} />;
    case "rightArrow":
      return <FaLongArrowAltRight size={size} {...props} />;
    case "roundPlus":
      return <FiPlusCircle size={size} {...props} />;
    case "envelop":
      return <FaRegEnvelopeOpen size={size} {...props} />;
    case "lock":
      return <IoLockClosedOutline size={size} {...props} />;
    case "unlock":
      return <IoLockOpenOutline size={size} {...props} />;
    case "logout":
      return <IoIosLogOut size={size} {...props} />;
    case "back":
      return <IoArrowBackOutline size={size} {...props} />;
    case "backangle":
      return <FaAngleLeft size={size} {...props} />;
    case "menu":
      return <IoMdMenu size={size} {...props} />;
    case "adduser":
      return <AiOutlineUserAdd size={size} {...props} />;
    case "insta":
      return <FaInstagram size={size} {...props} />;
    case "facebook":
      return <FaFacebookSquare size={size} {...props} />;
    case "arrowDown":
      return <FaArrowAltCircleDown size={size} {...props} />;
    case "calendar":
      return <CiCalendarDate size={size} {...props} />;
    case "list":
      return <FaListOl size={size} {...props} />;
    case "buy":
      return <BiPurchaseTag size={size} {...props} />;
    case "shortcut":
      return <MdOutlineShortcut size={size} {...props} />;
    case "dropdown":
      return <IoIosArrowDropdown size={size} {...props} />;
    case "filter":
      return <IoFilterSharp size={size} {...props} />;
    case "accountant":
      return <MdOutlineManageAccounts size={size} {...props} />;
    case "sites":
      return <FaSitemap size={size} {...props} />;
    default:
      return null;
  }
};

export default IconResource;
