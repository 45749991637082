import { useState, useRef, useEffect } from "react";
import AccontantsUI from "./AccontantsUI";
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer } from "../../Utils/dateUtils";
import * as commonServices from "../../Services/common";
import { isLengthInBetween, isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";

const Accountants = () => {
    const [modalShow, setModalShow] = useState(false);
    const [accountantList, setAccountantList] = useState([]);
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();

    const initialAccountantData = {
        id: null,
        site_id: "",
        name: "",
        email: "",
        password: "",
        remark: "",
        action: "Add",
    }
    const [accountantData, setAccountantData] = useState(initialAccountantData);

    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getAccountantList();
        }
    }, [activePage, dates, searchQuery]);

    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };

    const getAccountantList = () => {
        const query = `?page=${activePage}&per_page=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&query=${searchQuery}`;

        setLoading(true);
        apiHelper.getAccountants(query).then((response) => {
            if (response?.isSuccess) {
                setAccountantList(response.data);
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    }

    const validateData = () => {
        const { site_id, name, email, password } = accountantData;
        let validData = true;

        if (isNullOrEmpty(site_id)) {
            toast.warn(stringConstants.SITE_NAME_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(name)) {
            toast.warn(stringConstants.ACCOUNTANT_NAME_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(email)) {
            toast.warn(stringConstants.EMAIL_REQUIRED);
            validData = false;
        } else if (!commonServices.isEmailValid(email)) {
            toast.warn(stringConstants.INCORRECT_EMAIL);
            validData = false;
        } else if (isNullOrEmpty(password)) {
            toast.warn(stringConstants.PASSWORD_REQUIRED);
            validData = false;
        } else if (!isLengthInBetween(password, 5, 12)) {
            toast.warn(stringConstants.INVALID_PASSWORD);
            validData = false;
        }

        return validData;
    };

    const upsertAccountant = () => {
        if (validateData()) {
            setButtonLoading(true);
            const data = {
                id: accountantData.id,
                site_id: accountantData.site_id,
                email: accountantData.email,
                password: accountantData.password,
                name: accountantData.name,
                remark: accountantData.remark,
            }

            apiHelper.upsertAccountant(data).then(response => {
                setButtonLoading(false);
                if (response?.isSuccess) {
                    toast.success(response?.message);
                    handleClose();
                    getAccountantList();
                } else {
                    toast.warn(response?.message);
                }
            });
        }
    }

    const clearData = () => {
        setAccountantData(initialAccountantData);
    }

    const handleClose = () => {
        setModalShow(false);
        clearData();
    }

    const handleEditClick = (data) => {
        setAccountantData({ id: data.id, site_id: data.site_id, name: data.name, email: data.email, remark: data.remark, action: 'Edit' });
        setModalShow(true);
    }

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowConfirmModal(true);
    }

    const handleDeleteConfirmation = () => {
        deleteAccountant(deleteId);
    }

    const deleteAccountant = (id) => {
        setButtonLoading(true);
        apiHelper.deleteAccountant(id).then((response) => {
            setButtonLoading(false);
            if (response?.isSuccess) {
                setShowConfirmModal(false);
                getAccountantList();
                toast.success(response.message);
            } else {
                toast.warn(response?.message);
            }
        });
    };

    return (
        <AccontantsUI
            loading={loading}
            buttonLoading={buttonLoading}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleClose={handleClose}
            accountantList={accountantList}
            activePage={activePage}
            onPageChange={onPageChange}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            handleDeleteConfirmation={handleDeleteConfirmation}
            dates={dates}
            setDates={setDates}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            accountantData={accountantData}
            setAccountantData={setAccountantData}
            upsertAccountant={upsertAccountant}
        />
    )
}

export default Accountants;
