import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);
}

export function logout(reqData) {
  return apiProvider.post(urlConstants.LOGOUT, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PROFILE, reqData)
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.GET_PROFILE)
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function getOrders(query, reqData) {
  return apiProvider.post(urlConstants.GET_ORDER_LIST + query, reqData);
}

export function upsertOrder(reqData) {
  return apiProvider.post(urlConstants.UPSERT_ORDER, reqData);
}

export function orderExport(query, reqData) {
  return apiProvider.post(urlConstants.EXPORT_ORDER + query, reqData);
}

export function getTransporters(query) {
  return apiProvider.getAll(urlConstants.GET_TRANSPORTER_LIST + query);
}

export function upsertTransporter(reqData) {
  return apiProvider.post(urlConstants.UPSERT_TRANSPORTER, reqData);
}

export function deleteTransporter(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_TRANSPORTER + id);
}

export function getSites(query) {
  return apiProvider.getAll(urlConstants.GET_SITE_LIST + query);
}

export function upsertSite(reqData) {
  return apiProvider.post(urlConstants.UPSERT_SITE, reqData);
}

export function deleteSite(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_SITE + id);
}

export function getAccountants(query) {
  return apiProvider.getAll(urlConstants.GET_ACCOUNTANT_LIST + query);
}

export function upsertAccountant(reqData) {
  return apiProvider.post(urlConstants.UPSERT_ACCOUNTANT, reqData);
}

export function deleteAccountant(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_ACCOUNTANT + id);
}

