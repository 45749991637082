import * as storageConstants from '../Constants/storageConstant';
import { BROWSER_STORAGE_ERROR } from '../Constants/stringConstants';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import * as apiHelper from "./ApiHelper";
import { formatValue } from 'react-currency-input-field';


const localStorageArray = [storageConstants.AUTH, storageConstants.PROFILE, storageConstants.USER_ROLE];
const sessionStorageArray = [storageConstants.SYSTEM_DATA];

export function getLocalData(name) {
    try {
        if (sessionStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.getItem(name) : JSON.parse(sessionStorage.getItem(name));
        }
        else if (localStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.getItem(name) : JSON.parse(localStorage.getItem(name));
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        if (sessionStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.setItem(name, data) : sessionStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        else if (localStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.setItem(name, data) : localStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        return false;
    }
}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }
}

export const getSystemData = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}
export const formattedValue = (val, prefix, allowDecimals = true) => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? "₹" : "",
    intlConfig: prefix ? { locale: 'en-IN', currency: 'INR' } : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export function isValidAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const n = a?.toString().replace(/,/g, '');
    return !isNaN(n);
}



export const fetchSystemData = async () => {
    await apiHelper.systemData().then((response) => {
        if (response?.isSuccess) {
            try {
                storeLocalData(storageConstants.SYSTEM_DATA, response?.data);
            } catch (e) {
                warn(BROWSER_STORAGE_ERROR);
            }
        }
    });
};

export const getSiteOptions = () => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);
    if (systemData) {
        return [...systemData.sites.map(s => ({
            label: s.name,
            value: s.id,
        }))];
    } else {
        return [];
    }
}

export const getTransporterOptions = () => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);

    if (systemData) {
        return [...systemData.transporters.map(t => ({
            label: t.name,
            value: t.id,
        }))];
    } else {
        return [];
    }
}
export const getTransporterOptionsFromIds = (ids) => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);

    if (!systemData || !systemData.transporters || !Array.isArray(ids)) {
        return [];
    }

    return ids.map(id => {
        const transporter = systemData.transporters.find(t => t.id === id);
        if (transporter) {
            return {
                label: transporter.name,
                value: transporter.id
            };
        } else {
            return null;
        }
    }).filter(option => option !== null);
};

export const getOptionsFromIds = (ids, property) => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);

    if (!systemData || !systemData[property]) {
        return [];
    }
    if (!ids || (Array.isArray(ids) && ids.length === 0)) {
        return [];
    }

    if (Array.isArray(ids)) {
        return ids.flatMap(id => {
            const item = systemData[property].find(t => t.id === id);
            return item ? [{ label: item.name, value: item.id }] : [];
        });
    } else {
        const item = systemData[property].find(t => t.id === parseInt(ids));
        return item ? [{ label: item.name, value: item.id }] : [];
    }
};


export const getAccountantOptions = () => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);

    if (systemData) {
        return [...systemData.accountant.map(a => ({
            label: `${a.name} (${a.site?.name})`,
            value: a.id,
        }))];
    } else {
        return [];
    }
}

export const getTransporterNames = (ids) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);
    const transporterNames = ids?.map((id) => {
        return data.transporters.find((transporters) => transporters.id === parseInt(id))?.name;
    });
    const filteredNames = transporterNames?.filter(name => name !== undefined);

    const namesJoined = filteredNames?.join(", ");
    return namesJoined
};

export const getMaterialOptions = () => {
    const systemData = getLocalData(storageConstants.SYSTEM_DATA);

    if (systemData) {
        return [...systemData.materials.map(m => ({
            label: m.name,
            value: m.id,
        }))];
    } else {
        return [];
    }
}

