import React from "react";
import Text from "../_widgets/Text/Text";
import { Form, Row, Col, InputGroup, Image } from "react-bootstrap";
import IconResource from "../../Wrapper/IconResource/IconResource";
import "./login.scss";
import { Link } from "react-router-dom";
import loginImage from "../../Assets/img/login_right.png";
import Button from "../_widgets/Button/Button";
import Input from "../_widgets/Input/Input";

const LoginUi = ({ location, setEmail, setPassword, email, password, login, loading }) => {
  return (
    <>
      <div className="vh100 bg_primary">
        <Row className="h-100 mx-0 flex-column-reverse flex-md-row">
          <Col md={7} className="bg-white rounded_right">
            <div className="d-flex align-items-center justify-content-between h-100 position-relative">
              {location.pathname !== "/" ? (
                <div className="back_btn">
                  <Button buttonType="back" />
                </div>
              ) : (
                ""
              )}
              <div className="login_form m-auto">
                <Text
                  className="login_header"
                  type={"h1"}
                  text="Welcome to Shri Ganesh Enterprises"
                />
                <Text
                  className="login_sub_heading"
                  type={"small"}
                  text="Enter your details to proceed further"
                />
                <Form className="mt-6">
                  <Form.Label aria-label="Email Adress">
                    Email Address
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter your Email"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      type="email"
                      value={email}
                      autoComplete="false"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <IconResource type="envelop" />
                    </InputGroup.Text>
                  </InputGroup>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    inputType="password"
                    label="Password"
                  />
                  <Row className="mx-0 mt-4 justify-content-between">
                    <Col md={6} className="ps-0 text-start  ">
                      <Form.Check
                        type={"checkbox"}
                        id={`default-radio`}
                        label={`Remember Me`}
                        className="text-black"
                      />
                    </Col>
                    <Col md={6} className="pe-0 text-end">
                      {/* <Link className="login_link" to={"/reset-password"}>
                        Recover Password
                      </Link> */}
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Button
                      buttonType={"primary"}
                      onClick={(e) => login(e)}
                      text="Sign In"
                      isLoading={loading}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="w-100 h-100 overflow-hidden side_image">
              <Image src={loginImage} className="img-fluid w-100 h-100" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginUi;
