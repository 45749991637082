import React from "react";
import Button from "../Button/Button";
import Text from "../Text/Text";
import IconResource from "../../../Wrapper/IconResource/IconResource";

const NavBar = ({
  heading,
  onClick,
  button,
  buttonIcon,
  buttonIconsuff,
  setModalShow,
  Icon,
}) => {
  const rightButtonHandler = () => {
    setModalShow(true);
  };
  return (
    <>
      <div className="nav_bar">
        <div className="start">
          <Button
            buttonType="icon"
            icon={<IconResource type={Icon} size={20} />}
            color="black"
            onClick={onClick}
          />
          <Text type="h2" text={heading} />
        </div>
        {button ? (
          <Button
            buttonType="primary"
            prefixIcon={
              buttonIcon ? <IconResource type={buttonIcon} size={18} /> : ""
            }
            suffixIcon={
              buttonIconsuff ? (
                <IconResource type={buttonIconsuff} size={18} />
              ) : (
                ""
              )
            }
            text={button}
            onClick={rightButtonHandler}
          />
        ) : null}
      </div>
    </>
  );
};

export default NavBar;
