import React from "react";
import { Form } from "react-bootstrap";
import Input from "../_widgets/Input/Input";
import Button from "../_widgets/Button/Button";
import "./ChangePassword.scss";
import BackBar from "../_widgets/BackBar/BackBar";
import AnimatedPage from "../AnimatedPage/AnimatedPage";

const ChangePasswordUI = ({ password, setPassword, changeProfilePassword, buttonLoading }) => {
  return (
    <AnimatedPage>
      <BackBar heading="Change Password" />
      <div className="change_password white_card">
        <Form className="text-start">
          <Input
            inputType="password"
            label="Old Password"
            placeholder="Enter Old Password"
            value={password.oldPassword}
            onChange={(e) =>
              setPassword({ ...password, oldPassword: e.target.value })
            }
          />
          <Input
            inputType="password"
            label="New Password"
            placeholder="Enter New Password"
            value={password.newPassword}
            onChange={(e) =>
              setPassword({ ...password, newPassword: e.target.value })
            }
          />
          <Input
            inputType="password"
            label="Confirm Password"
            placeholder="Confirm New Password"
            value={password.confirmPassword}
            onChange={(e) =>
              setPassword({ ...password, confirmPassword: e.target.value })
            }
          />

          <div className="mt-4 d-flex justify-content-end">
            <Button
              buttonType={"primary"}
              text="Change Password"
              isLoading={buttonLoading}
              onClick={(e) => changeProfilePassword(e)}
            />
          </div>
        </Form>
      </div>
    </AnimatedPage>
  );
};

export default ChangePasswordUI;
