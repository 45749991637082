import Text from "../_widgets/Text/Text";
import Input from "../_widgets/Input/Input";
import noData from "../../Assets/img/noData.png";
import Button from "../_widgets/Button/Button";
import NavBar from "../_widgets/NavBar/NavBar";
import { Table } from "react-bootstrap";
import BasicModal from "../_widgets/Modal/BasicModal";
import { Row, Col } from "react-bootstrap";
import CustomPagination from "../_widgets/Pagination/Pagination";
import DateRangePicker from "../_widgets/DateRangePicker/DateRangePicker";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import CustomTooltip from '../_widgets/Tooltip/CustomTooltip';
import IconResource from '../../Wrapper/IconResource/IconResource';
import { getOptionsFromIds, getSiteOptions } from '../../Services/common';
import ModalConfirmation from '../_widgets/Modal/ModalConfirmation';
import MainLoader from '../_widgets/Loader/MainLoader';
import { isNullOrEmpty } from '../../Utils/stringUtils';
import { warn } from '../../Wrapper/toast/toast';
import { maximumLengthAllowed } from '../../Constants/stringConstants';

const AccontantsUI = ({ loading, buttonLoading, modalShow, setModalShow, handleClose, accountantList, activePage, onPageChange, showConfirmModal, setShowConfirmModal, handleDeleteConfirmation, dates, setDates, datePickerStartRef, datePickerEndRef, searchQuery, setSearchQuery, handleEditClick, handleDeleteClick, accountantData, setAccountantData, upsertAccountant, }) => {
    const accountants = getOptionsFromIds(accountantData?.site_id, "sites")

    return (
        <AnimatedPage>
            <NavBar
                button="Add Accountant"
                buttonIcon={"accountant"}
                heading={`All Accountants`}
                onClick={() => document.body.classList.toggle("side_active")}
                setModalShow={setModalShow}
                Icon="menu"
            />

            <ModalConfirmation
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                heading='Delete Confirmation'
                buttonLoading={buttonLoading}
                button1Click={handleDeleteConfirmation}
                button1Text={'Delete'}
            />

            <BasicModal
                show={modalShow}
                size="lg"
                setModalShow={setModalShow}
                handleClose={handleClose}
                heading={`${accountantData.action} Accountant`}
                footer
                button2Text={"Save"}
                button2Click={upsertAccountant}
                loading={buttonLoading}
            >
                <div className="patients_ui">
                    <Row>
                        <Col xl={12}>
                            <div className="white_card text-start">
                                <Row className="mb-3">
                                    <Col lg={6}>
                                        <Dropdown
                                            label="Site Name"
                                            placeholder="Site Name"
                                            options={getSiteOptions()}
                                            name={accountantData?.site_id}
                                            preselected={accountants}
                                            onChange={value => setAccountantData({ ...accountantData, site_id: value.value })}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Input
                                            placeholder="Enter Accountant Name"
                                            type="text"
                                            label="Accountant Name"
                                            value={accountantData.name}
                                            onChange={e => {
                                                if (e.target.value.length <= 20) {
                                                    setAccountantData({ ...accountantData, name: e.target.value })
                                                } else {
                                                    warn(maximumLengthAllowed(20))
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col lg={6}>
                                        <Input
                                            placeholder="Enter Email"
                                            type="email"
                                            label="Email"
                                            value={accountantData.email}
                                            onChange={e => {
                                                if (e.target.value.length <= 30) {
                                                    setAccountantData({ ...accountantData, email: e.target.value })
                                                } else {
                                                    warn(maximumLengthAllowed(30))
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Input
                                            placeholder="Enter Password"
                                            type="password"
                                            label="Password"
                                            value={accountantData.password}
                                            onChange={e => {
                                                if (e.target.value.length <= 12) {
                                                    setAccountantData({ ...accountantData, password: e.target.value })
                                                } else {
                                                    warn(maximumLengthAllowed(12))
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <Input
                                            placeholder="Enter Notes"
                                            type="text"
                                            as="textarea"
                                            rowspan={4}
                                            label="Notes (Optional)"
                                            value={accountantData.remark}
                                            onChange={e => {
                                                if (e.target.value.length <= 30) {
                                                    setAccountantData({ ...accountantData, remark: e.target.value })
                                                } else {
                                                    warn(maximumLengthAllowed(30))
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                </div>
            </BasicModal>

            <div className="patient_list white_card">
                <div className="top_bar">
                    <Text type="h2" text="All Accountants" />
                    <Row className="search_filter">
                        <Col lg={5} xs={12} sm={4}>
                            <div>
                                <Input
                                    placeholder="Search"
                                    id="searchInput"
                                    suffixIcon={`${searchQuery.length > 0 ? "cross" : "search"}`}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onClickIconSuff={() => setSearchQuery("")}
                                />
                            </div>
                        </Col>
                        <Col lg={7} sm={8}>
                            <div className="date_range_picker">
                                <DateRangePicker
                                    startDate={dates?.startDate}
                                    setStartDate={(startDate) => setDates({ ...dates, startDate })}
                                    endDate={dates?.endDate}
                                    setEndDate={(endDate) => setDates({ ...dates, endDate })}
                                    onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                                    datePickerStartRef={datePickerStartRef}
                                    datePickerEndRef={datePickerEndRef}
                                    showDateShortcuts={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="custom_table position-relative">
                    {loading ? (
                        <MainLoader />
                    ) : accountantList?.data?.length > 0 ? (
                        <>
                            <div>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Site Name</th>
                                            <th>Transporter Name</th>
                                            <th>Email</th>
                                            <th>Remarks</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {accountantList?.data?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.site?.name}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.email}</td>
                                                    <td>{isNullOrEmpty(data.remark) ? '-' : data.remark}</td>
                                                    <td><div className="d-flex align-items-center gap-2 justify-content-center">
                                                        <CustomTooltip text="Edit" placement={"top"}>
                                                            <Button
                                                                className="p-1 bg-transparent border-0"
                                                                icon={
                                                                    <IconResource type="edit" color="var(--light-text)" />
                                                                }
                                                                onClick={() => handleEditClick(data)}
                                                            />
                                                        </CustomTooltip>
                                                        <CustomTooltip text="Delete" placement={"top"}>
                                                            <Button
                                                                className="p-1 bg-transparent border-0"
                                                                icon={<IconResource type="trash" color="red" />}
                                                                onClick={() => handleDeleteClick(data?.id)}
                                                            />
                                                        </CustomTooltip>
                                                    </div></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            <CustomPagination
                                activePage={activePage}
                                onPageChange={onPageChange}
                                pageLength={accountantList?.per_page}
                                totalData={accountantList?.total}
                                lastPage={accountantList?.data.last_page}
                            />
                        </>
                    ) : (
                        <>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Site Name</th>
                                        <th>Transporter Name</th>
                                        <th>Email</th>
                                        <th>Remarks</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </Table>
                            <div className="no_data position-relative">
                                <img
                                    src={noData}
                                    className="img-fluid"
                                    style={{ width: "300px" }}
                                />
                                <Text type="h2" text="There is no data to display." />
                                {/* <Button buttonType="primary" text="Add Medicine" /> */}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </AnimatedPage>
    )
}

export default AccontantsUI