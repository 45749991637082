import React from "react";
import Button from "../Button/Button";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import Text from "../Text/Text";
import { useNavigate } from "react-router-dom";

const BackBar = ({ heading }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigates back one step in the history stack
  };
  return (
    <>
      <div className="back_bar">
        <Button
          buttonType="back"
          icon={<IconResource type="backangle" />}
          color="black"
          onClick={goBack}
        />

        <Text type="h2" text={heading} />
      </div>
    </>
  );
};

export default BackBar;
