import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Text from "../_widgets/Text/Text";
import NavBar from "../_widgets/NavBar/NavBar";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import coming from "../../Assets/img/coming.avif";

const DashboardUI = () => {
  return (
    <AnimatedPage>
      <div className="dashboard-ui">
        <NavBar
          heading="Dashboard"
          Icon="menu"
          onClick={() => document.body.classList.toggle("side_active")}
        />
        <div className="dashboard-content">
          <img src={coming} style={{ opacity: "0.1" }} />
        </div>
      </div>
    </AnimatedPage>
  );
};

export default DashboardUI;
