import React from "react";
import "./Link.scss";
import IconResource from "../../../Wrapper/IconResource/IconResource";

const Link = (props) => {
  let requiredClass =
    isUndefined(props?.isRequired) || !props?.isRequired ? "" : "required";
  let label = isUndefined(props?.label) ? props?.placeholder : props?.label;
  let showDefaultOption = isUndefined(props?.showDefaultOption)
    ? false
    : props?.showDefaultOption;
  function isUndefined(value) {
    return value === undefined;
  }

  const render = () => {
    switch (props?.type) {
      case "a":
        return (
          <a
            className={props?.className}
            data-bs-toggle={props?.dataBsToggle}
            role={props?.role}
            aria-controls={props?.ariaControls}
            aria-expanded={props?.ariaExpanded}
            aria-current={props?.ariaCurrent}
            aria-label={props?.ariaLabel}
            data-bs-target={props?.dataBsTarget}
            href={props?.aHref}
            onClick={props?.onClick}
            id={props?.id}
            dangerouslySetInnerHTML={{ __html: props?.text }}
            {...props}
          ></a>
        );
      case "icon":
        return (
          <a
            className={props?.className}
            data-bs-toggle={props?.dataBsToggle}
            role={props?.role}
            aria-controls={props?.ariaControls}
            aria-expanded={props?.ariaExpanded}
            aria-current={props?.ariaCurrent}
            aria-label={props?.ariaLabel}
            data-bs-target={props?.dataBsTarget}
            href={props?.aHref}
            onClick={props?.onClick}
            id={props?.id}
          >
            {props?.text}{" "}
            {
              <IconResource
                type={props?.iconType}
                color={props?.iconColor}
                className="action-icon"
              />
            }
          </a>
        );
      default:
        return (
          <>
            <p>{props?.text}</p>
          </>
        );
    }
  };

  return <>{render()}</>;
};

export default Link;
