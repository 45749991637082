// response.js
import * as commonServices from './common';
import * as toast from "../Wrapper/toast/toast"
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED } from '../Constants/responseCodes';
import { LOGIN_AGAIN } from '../Constants/stringConstants';

export function handleResponse(response) {
    if (response.data.code === SUCCESS_CODE || response.data === RESOURCE_CREATED) {
        response.data.isSuccess = true;
    } else if (response.data.code === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        toast.warn(LOGIN_AGAIN);
        return;
    }
    else {
        response.data.isSuccess = false;
    }
    return response.data;
}

export function handleError(error) {

    if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        toast.warn(LOGIN_AGAIN);
        return;
    }

}