import React, { useEffect, useState } from "react";
import LoginUi from "./LoginUi";
import * as apiHelper from "../../Services/ApiHelper";
import { useNavigate } from "react-router-dom";
import * as toast from "../../Wrapper/toast/toast";
import * as stringConstants from "../../Constants/stringConstants";
import * as commonServices from "../../Services/common";
import * as storageConstants from "../../Constants/storageConstant";
import { isNullOrEmpty } from "../../Utils/stringUtils";

const Login = ({ location, auth }) => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();

    if (!email || isNullOrEmpty(email)) {
      toast.warn(stringConstants.EMAIL_REQUIRED);
    } else if (!commonServices.isEmailValid(email)) {
      toast.warn(stringConstants.INCORRECT_EMAIL);
    } else if (!password || isNullOrEmpty(password)) {
      toast.warn(stringConstants.PASSWORD_REQUIRED);
    } else if (password.length < 6 || password.length > 12) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    } else {
      setLoading(true);
      const response = await apiHelper.login({ email, password });
      setLoading(false);
      if (response?.isSuccess) {
        if (response.data.role_name === 'admin') {
          commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
          commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_name);
          commonServices.storeLocalData(storageConstants.PROFILE_NAME, response.data.name);
          toast.success(response.message);
          commonServices.fetchSystemData();
          navigate("/orders");
        } else {
          toast.error(stringConstants.CONTACT_ADMIN);
        }
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <LoginUi
      location={location}
      setEmail={setEmail}
      setPassword={setPassword}
      login={login}
      email={email}
      password={password}
      loading={loading}
    />
  );
};

export default Login;
