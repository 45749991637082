import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import IconResource from "../../Wrapper/IconResource/IconResource";
import "./HomeLayout.scss";
import { Image, Dropdown } from "react-bootstrap";
import Avatar from "../../Assets/img/profile-pic.jpeg";
import Text from "../_widgets/Text/Text";
import Logo from "../../Assets/img/logo.png";
import Button from "../_widgets/Button/Button";
import Link from "../_widgets/Link/Link";
import * as storageConstants from "../../Constants/storageConstant";
import { clearLocalStorageData } from "../../Services/common";

const HomeLayout = () => {
  const logout = () => {
    localStorage.removeItem("auth");
    clearLocalStorageData();
  };

  const profileName = localStorage.getItem(storageConstants.PROFILE_NAME);

  return (
    <div className="home">
      <div className="overlay-sidebar">
        <div className="sidebar">
          <div className="closebtn">
            <Button
              buttonType="icon"
              icon={<IconResource type="back" size={20} />}
              onClick={() => document.body.classList.toggle("side_active")}
            />
          </div>

          <ul>
            <li className="bg-transparent">
              <div className="logo">
                <Image src={Logo} className="img-fluid" />
              </div>
            </li>

            <li onClick={() => document.body.classList.toggle("side_active")}>
              <NavLink to="/dashboard">
                <IconResource type="dashboard" size={20} />
                Dashboard
              </NavLink>
            </li>

            <li onClick={() => document.body.classList.toggle("side_active")}>
              <NavLink to="/orders">
                <IconResource type="bag" size={20} />
                Orders
              </NavLink>
            </li>

            <li onClick={() => document.body.classList.toggle("side_active")}>
              <NavLink to="/transporters">
                <IconResource type="users" size={20} />
                Transporters
              </NavLink>
            </li>

            <li onClick={() => document.body.classList.toggle("side_active")}>
              <NavLink to="/sites">
                <IconResource type="sites" size={25} />
                Sites
              </NavLink>
            </li>

            <li onClick={() => document.body.classList.toggle("side_active")}>
              <NavLink to="/accountants">
                <IconResource type="accountant" size={25} />
                Accountants
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>
              <div className="profile_menu">
                <div className="profile_pic">
                  <Image src={Avatar} />
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    className="p-0 m-0 border-0"
                    variant="transparent"
                    id="dropdown-basic"
                  >
                    {profileName || 'User'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    <Dropdown.Item>
                      <NavLink className="profile_menu" to="/profile">
                        <div className="profile_pic">
                          <Image src={Avatar} />
                        </div>
                        <Text text={profileName || 'User'} />
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/change-password" className="profile_menu">
                        <div className="profile_pic">
                          <IconResource type={"lock"} />
                        </div>
                        <Text text="Change Password" />
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/" onClick={logout} className="profile_menu">
                        <div className="profile_pic">
                          <IconResource type="logout" />
                        </div>
                        <Text text="Logout" />
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="main_outlet">
        <div className="minh80">
          <Outlet />
        </div>
        <div className="footer">
          <Text text="Copyright © 2024. All rights reserved." />
          <Link
            type={"a"}
            target='_blank'
            aHref="https://www.appringer.com"
            text="Made with ❤️ by App Ringer"
            style={{ textDecoration: "none", color: "var(--light-text)", fontSize: "var(--p)" }}
          />
          <div className="social">
            <div className="icon">
              <Link
                type={"icon"}
                aHref="instagram.com"
                iconType="insta"
                iconColor={"var(--light-text)"}
                target={"_blank"}
              />

            </div>
            <div className="icon">
              <Link
                type={"icon"}
                aHref="facebook.com"
                iconType="facebook"
                iconColor={"var(--light-text)"}
                target={"_blank"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
