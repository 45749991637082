import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import * as commonServices from './Services/common';
import * as storageConstants from './Constants/storageConstant';

import ProtectedRoute from './Protected/Protected';
import Login from './Components/Login/Login';
import LoginLayout from './Components/Layouts/LoginLayout';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import HomeLayout from './Components/Layouts/HomeLayout';
import Dashboard from './Components/Dashboard/Dashboard';
import Accountants from './Components/Accountants/Accountants';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import Orders from './Components/Orders/Orders';
import Transporters from './Components/Transporters/Transporters';
import Profile from './Components/Profile/Profile';
import Sites from './Components/Sites/Sites';
import NotFound from './Components/NotFound/NotFound';

function App() {
  const location = window.location;

  const sysData = commonServices.getLocalData(storageConstants.SYSTEM_DATA);
  const auth = commonServices.getLocalData(storageConstants.AUTH);

  useEffect(() => {
    if (sysData === null && auth) {
      commonServices.fetchSystemData();
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <Routes>
          <Route element={<LoginLayout />}>
            <Route exact path='/' element={<ProtectedRoute Component={<Login auth={auth} location={location} />} />} />
            <Route exact path='/reset-password' element={<ResetPassword location={location} />} />
            <Route exact path="/*" element={<NotFound />} />
          </Route>

          <Route element={<HomeLayout />}>
            <Route exact path="/change-password" element={<ProtectedRoute Component={<ChangePassword />} />} />
            <Route exact path="/profile" element={<ProtectedRoute Component={<Profile />} />} />
            <Route exact path="/dashboard" element={<ProtectedRoute Component={<Dashboard />} />} />
            <Route exact path="/orders" element={<ProtectedRoute Component={<Orders />} />} />
            <Route exact path="/transporters" element={<ProtectedRoute Component={<Transporters />} />} />
            <Route exact path="/sites" element={<ProtectedRoute Component={<Sites />} />} />
            <Route exact path="/accountants" element={<ProtectedRoute Component={<Accountants />} />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
