import { Form } from "react-bootstrap";
import Input from "../_widgets/Input/Input";
import Button from "../_widgets/Button/Button";
import BackBar from "../_widgets/BackBar/BackBar";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import MainLoader from '../_widgets/Loader/MainLoader';
import { warn } from '../../Wrapper/toast/toast';
import { maximumLengthAllowed } from '../../Constants/stringConstants';
import { preventPlusMinusInNumberInput } from '../../Services/common';

const ProfileUI = ({ profile, setProfile, handleUpdateProfile, loading, buttonLoading }) => {
    return (
        <AnimatedPage>
            <BackBar heading="Profile" />
            <div className=" white_card position-relative" style={{
                width: "100",
                maxWidth: "500px",
                minWidth: "450px",
                minHeight: "300px"
            }}>
                {loading ?
                    <MainLoader /> :
                    <Form className="text-start">
                        <Input
                            type="text"
                            label="Name"
                            placeholder="Enter Your Name"
                            value={profile.name}
                            onChange={(e) => {
                                if (e.target.value.length <= 30) {
                                    setProfile({ ...profile, name: e.target.value });
                                } else {
                                    warn(maximumLengthAllowed(30))
                                }
                            }}
                        />
                        <Input
                            type="number"
                            label="Mobile No"
                            placeholder="Enter Mobile No"
                            onKeyDown={e => preventPlusMinusInNumberInput(e)}
                            value={profile.mno}
                            onChange={(e) => {
                                if (e.target.value.length <= 14) {
                                    setProfile({ ...profile, mno: e.target.value });
                                } else {
                                    warn(maximumLengthAllowed(14))
                                }
                            }}
                        />
                        <div className="mt-4 d-flex justify-content-end">
                            <Button
                                buttonType={"primary"}
                                text="Update Profile"
                                onClick={handleUpdateProfile}
                                isLoading={buttonLoading}
                            />
                        </div>
                    </Form>}
            </div>
        </AnimatedPage>
    );
}

export default ProfileUI;
