import React from "react";
import { Dropdown, Form } from "react-bootstrap";

const MultiSelectDropdown = ({ items, selectedOptions, onChange, name }) => {

  const handleCheckboxChange = (option) => {
    const updatedOptions = [...selectedOptions];
    const index = updatedOptions.indexOf(option);
    if (index === -1) {
      updatedOptions.push(option);
    } else {
      updatedOptions.splice(index, 1);
    }
    onChange(updatedOptions);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {name}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: "200px" }}>
        <Form className="px-3">
          <Form.Group controlId="formBasicCheckbox">
            {items.map((item, i) => (
              <Form.Check
                key={item + i}
                type="checkbox"
                label={item.name || item.label}
                className="py-2"
                checked={selectedOptions.includes(item.value)}
                onChange={() => handleCheckboxChange(item.value)}
              />
            ))}
          </Form.Group>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
