import { useRef, useState, useEffect } from 'react'
import TransportersUI from './TransportersUI';
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer } from "../../Utils/dateUtils";
import * as commonServices from "../../Services/common";
import { isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";

const Transporters = () => {
    const [modalShow, setModalShow] = useState(false);
    const [transporterList, setTransporterList] = useState([]);
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();

    const initialTransporterData = {
        id: null,
        name: "",
        remark: "",
        action: "Add",
    }
    const [transporterData, setTransporterData] = useState(initialTransporterData);

    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getTransporterList();
        }
    }, [activePage, dates, searchQuery]);

    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };

    const getTransporterList = () => {
        const query = `?page=${activePage}&per_page=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&query=${searchQuery}`;

        setLoading(true);
        apiHelper.getTransporters(query).then((response) => {
            if (response?.isSuccess) {
                setTransporterList(response.data);
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    }

    const validateData = () => {
        const { name, remark } = transporterData;
        let validData = true;

        if (isNullOrEmpty(name)) {
            toast.warn(stringConstants.TRANSPORTER_NAME_REQUIRED);
            validData = false;
        } else if (isNullOrEmpty(remark)) {
            toast.warn(stringConstants.TRANSPORTER_REMARKS_REQUIRED);
            validData = false;
        }

        return validData;
    };

    const upsertTransporter = () => {
        if (validateData()) {
            setButtonLoading(true);
            const data = {
                id: transporterData.id,
                name: transporterData.name,
                remark: transporterData.remark,
            }

            apiHelper.upsertTransporter(data).then(response => {
                setButtonLoading(false);
                if (response?.isSuccess) {
                    toast.success(response?.message);
                    handleClose();
                    commonServices.fetchSystemData();
                    getTransporterList();
                } else {
                    toast.warn(response?.message);
                }
            });
        }
    }

    const clearTransporterData = () => {
        setTransporterData(initialTransporterData);
    }

    const handleClose = () => {
        setModalShow(false);
        clearTransporterData();
    }

    const handleEditClick = (data) => {
        setTransporterData({ id: data.id, name: data.name, remark: data.remark, action: 'Edit' });
        setModalShow(true);
    }

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowConfirmModal(true);
    }

    const handleDeleteConfirmation = () => {
        deleteTransporter(deleteId);
    }

    const deleteTransporter = (id) => {
        setButtonLoading(true);
        apiHelper.deleteTransporter(id).then((response) => {
            setButtonLoading(false);
            if (response?.isSuccess) {
                setShowConfirmModal(false);
                getTransporterList();
                commonServices.fetchSystemData();
                toast.success(response.message);
            } else {
                toast.warn(response?.message);
            }
        });
    };

    return (
        <TransportersUI
            loading={loading}
            buttonLoading={buttonLoading}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleClose={handleClose}
            transporterList={transporterList}
            activePage={activePage}
            onPageChange={onPageChange}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            handleDeleteConfirmation={handleDeleteConfirmation}
            dates={dates}
            setDates={setDates}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            transporterData={transporterData}
            setTransporterData={setTransporterData}
            upsertTransporter={upsertTransporter}
        />
    )
}

export default Transporters