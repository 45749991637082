import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as storageConstants from '../Constants/storageConstant'
import * as commonServices from '../Services/common'
import { warn } from '../Wrapper/toast/toast'
import { LOGIN_AGAIN } from '../Constants/stringConstants'

const ProtectedRoute = ({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = commonServices.getLocalData(storageConstants.AUTH);
    if (!isAuthenticated) {
      navigate("/");
      warn(LOGIN_AGAIN);
    } else if (isAuthenticated && location.pathname === "/") {
      navigate("/orders")
    }
  }, [location.pathname]);

  return (
    <>
      {Component}
    </>
  )
}

export default ProtectedRoute;
